import { CircularProgress } from "@mui/material";
import { useState } from "react";
import LogoLayer from "../../../layers/logo/LogoLayer";
import "./Forms.css";

export type Data = {
  healthAreas: string;
  otherHealthAreas?: string;
  location: string;
  communication: string;
};

type Errors = {
  healthAreas?: string;
  otherHealthAreas?: string;
  location?: string;
  communication?: string;
};

const healthOptions = [
  "Longevity",
  "Weight Management",
  "Cognitive Function",
  "Cardiovascular Health",
  "Immune Support",
  "Digestive Health",
  "Other",
];

type Props = {
  backToLandingCallback?: () => void;
};

const HealthForm = ({ backToLandingCallback }: Props) => {
  const [info, setInfo] = useState<Data>({
    healthAreas: "",
    otherHealthAreas: "",
    location: "",
    communication: "",
  });
  const [errors, setErrors] = useState<Errors>({});
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSend = (): void => {
    if (isLoading) return;
    setIsLoading(true);
    setIsLoading(false);
    if (!validate()) return;

    setShowSuccess(true);
  };

  const validate = (): boolean => {
    const validationErrors: Errors = {};

    if (!info.healthAreas) {
      validationErrors.healthAreas = "Please select a health area.";
    }

    if (info.healthAreas === "Other" && !info.otherHealthAreas) {
      validationErrors.otherHealthAreas = "Please specify the health area.";
    }

    if (!info.location) {
      validationErrors.location = "Please enter your location.";
    }

    if (!info.communication) {
      validationErrors.communication =
        "Please enter your preferred method of communication.";
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ): void => {
    const propName = event.target.name;
    const propValue = event.target.value;

    setInfo((prev) => ({
      ...prev,
      [propName]: propValue,
    }));

    setErrors((prev) => ({
      ...prev,
      [propName]: undefined,
    }));

    if (propName === "healthAreas" && propValue !== "") {
      setErrors((prev) => ({
        ...prev,
        otherHealthAreas: undefined,
      }));
    }
  };

  return (
    <>
      <LogoLayer
        backToLandingCallback={backToLandingCallback}
        marginBottom={`${
          errors.otherHealthAreas && errors.location && errors.communication
            ? "1%"
            : "2%"
        }`}
      />
      {showSuccess && (
        <div className="end-message">
          <p>
            Thank you for submitting your request. Someone will get in touch
            with you soon!
          </p>
          <button style={{}} onClick={backToLandingCallback}>
            HOME PAGE
          </button>
        </div>
      )}
      {!showSuccess && (
        <div className="questionnaire-main-cont">
          <div className="top-left-icon">
            <img
              src={"/backArrow.svg"}
              alt="freshearth"
              onClick={backToLandingCallback}
            />
          </div>
          <p className="questionnaire-title">
            Welcome! Please let us know more about you.
          </p>
          <div className="questionnaire-input-box">
            <label>
              Which areas of health are you most interested in improving?
            </label>
            <select
              name="healthAreas"
              value={info.healthAreas}
              onChange={handleInputChange}
              style={{ marginBottom: !errors.healthAreas ? "10px" : 0 }}
            >
              <option value="" disabled>
                Select an option
              </option>
              {healthOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <span
              className="questionnaire-err-text"
              hidden={!errors.healthAreas}
              style={{ marginBottom: errors.healthAreas ? "10px" : 0 }}
            >
              {errors.healthAreas}
            </span>
            {info.healthAreas === "Other" && (
              <input
                type="text"
                name="otherHealthAreas"
                value={info.otherHealthAreas || ""}
                onChange={handleInputChange}
                placeholder="Please specify"
                style={{ marginBottom: !errors.otherHealthAreas ? "10px" : 0 }}
              />
            )}
            <span
              className="questionnaire-err-text"
              hidden={!errors.otherHealthAreas}
              style={{ marginBottom: errors.otherHealthAreas ? "10px" : 0 }}
            >
              {errors.otherHealthAreas}
            </span>
            <div className="questionnaire-input-box">
              <label>Where are you located? </label>
              <input
                name="location"
                value={info.location}
                onChange={handleInputChange}
              />
              <span
                className="questionnaire-err-text"
                hidden={!errors.location}
              >
                {errors.location}
              </span>
            </div>

            <div className="questionnaire-input-box">
              <label>How would you like us to communicate with you?</label>
              <input
                name="communication"
                value={info.communication}
                onChange={handleInputChange}
                placeholder="Phone, email, text, visit?"
              />
              <span
                className="questionnaire-err-text"
                hidden={!errors.communication}
              >
                {errors.communication}
              </span>
            </div>
          </div>
          <div className="questionnaire-btn no-select" onClick={handleSend}>
            {isLoading ? (
              <CircularProgress sx={{ mr: 1, color: "white" }} size={25} />
            ) : (
              "Send"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HealthForm;
