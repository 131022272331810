import { ContactInformation } from "../components/contact/Contact";

export const createContactEmailBody = (
  info: ContactInformation,
  pathToContact: string[]
) => {
  const pathInfo =
    pathToContact && pathToContact.length > 0
      ? pathToContact.join(" > ")
      : "Redirected from application";

  return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Contact Form Submission</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 20px;
              }
              .container {
                  border: 1px solid #ddd;
                  padding: 20px;
                  border-radius: 5px;
              }
              .header {
                  font-size: 18px;
                  font-weight: bold;
                  margin-bottom: 10px;
              }
              .content {
                  margin-top: 10px;
              }
              .path-info {
                  margin-top: 20px;
                  font-style: italic;
                  color: #555;
              }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="header">
                  You have a new message submitted via the contact form on website.
              </div>
              <div class="content">
                  <p><strong>From:</strong> ${info.firstName} ${info.lastName}</p>
                  <p><strong>Email:</strong> ${info.email}</p>
                  <p><strong>Message:</strong></p>
                  <p>${info.message}</p>
              </div>
              <div class="path-info">
                  <p><strong>Path to Contact Form:</strong> ${pathInfo}</p>
              </div>
          </div>
      </body>
      </html>
    `;
};
