import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./LogoLayer.css";

type LogoLayerProps = {
  hidden?: boolean;
  backToLandingCallback?: () => void;
  marginBottom?: string;
  width?: string;
};

const LogoLayer = ({
  hidden = false,
  backToLandingCallback,
  marginBottom = "2%",
  width = "200px",
}: LogoLayerProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const hash = window.location.hash;
  const token = hash.startsWith("#") ? hash.substring(1) : undefined;
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const route = token ? `/#${token}` : "/";

  return (
    <img
      src="/logo.png"
      alt="Logo"
      className="logo-main"
      style={{
        display: hidden ? "none" : "block",
        width: isMobile ? "124px" : width,
        marginBottom: marginBottom,
      }}
      onClick={() => {
        navigate(route);
        backToLandingCallback && backToLandingCallback();
      }}
    />
  );
};

export default LogoLayer;
