import { useMediaQuery, useTheme } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoLayer from "../components/layers/logo/LogoLayer";
import PlanetSplineLayer, {
  PlanetSplineProps,
} from "../components/layers/planet/PlanetSplineLayer";
import Animation from "../components/steps/animation/Animation";
import Main from "../components/steps/main/MainStep";
import Step from "../components/steps/other/Step";
import Questionnaire from "../components/steps/questionnaire/Questionnaire";
import {
  Steps,
  textAgricultureSubtype,
  textAgricultureType,
  textHealthSubtype,
  textHealthType,
  textIndustry,
} from "../constants/step";
import { Info } from "../types/info";

const HomePage = () => {
  const hash = window.location.hash;
  const token = hash.startsWith("#") ? hash.substring(1) : undefined;

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [step, setStep] = useState(Steps.Landing);
  const [info, setInfo] = useState<Info | undefined>(undefined);
  const [pathToContact, setPathToContact] = useState<string[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");
  const [, setSelectedType] = useState<string>("");
  const [, setSelectedSubtype] = useState<string>("");

  const planetPropsMainStep: PlanetSplineProps = {
    scaleFactor: 2,
  };

  const planetPropsOtherSteps: PlanetSplineProps = {
    scaleFactor: 1.9,
    top: isMobile ? 20 : 30,
    opacity: step === Steps.Animation ? 0 : 1,
  };

  useEffect(() => {
    try {
      if (token) {
        const decodedInfo = jwtDecode(token.substring(1)) as any;
        setInfo(decodedInfo?.data);
      } else {
        setInfo(undefined);
      }
    } catch (error) {
      navigate("/");
    }
  }, [token, navigate]);

  const handleNextStep = () => {
    setStep((prev: number) => prev + 1);
  };

  const goToAnimation = () => {
    setStep(Steps.Animation);
  };

  const backToLandingCallback = () => {
    setStep(Steps.Landing);
  };

  let availableIndustryTypeButtons: string[] = [];
  let availableIndustrySubtypeButtons: string[] = [];

  switch (selectedIndustry) {
    case "Agriculture":
      availableIndustryTypeButtons = textAgricultureType;
      availableIndustrySubtypeButtons = textAgricultureSubtype;
      break;
    case "Health":
      availableIndustryTypeButtons = textHealthType;
      availableIndustrySubtypeButtons = textHealthSubtype;
      break;
  }

  return (
    <>
      <LogoLayer
        hidden={
          (step === Steps.Landing && !isMobile) ||
          step === Steps.Animation ||
          step === Steps.Questionnaire
        }
        backToLandingCallback={backToLandingCallback}
      />

      <PlanetSplineLayer
        {...(step === Steps.Landing
          ? planetPropsMainStep
          : planetPropsOtherSteps)}
      />
      {step === Steps.Landing && <Main nextStepCallback={handleNextStep} />}
      {step === Steps.Industry && (
        <Step
          step={Steps.Industry}
          buttons={textIndustry}
          unlockedButton={info?.industry}
          nextStepCallback={handleNextStep}
          pathToContact={pathToContact}
          setPathToContact={setPathToContact}
          setValue={setSelectedIndustry}
          goToAnimation={goToAnimation}
          backToLandingCallback={backToLandingCallback}
        />
      )}
      {step === Steps.IndustryType && (
        <Step
          step={Steps.IndustryType}
          buttons={availableIndustryTypeButtons}
          unlockedButton={info?.industryType}
          nextStepCallback={handleNextStep}
          pathToContact={pathToContact}
          setPathToContact={setPathToContact}
          setValue={setSelectedType}
          backToLandingCallback={backToLandingCallback}
        />
      )}
      {step === Steps.IndustrySubType && (
        <Step
          step={Steps.IndustrySubType}
          buttons={availableIndustrySubtypeButtons}
          unlockedButton={info?.industrySubType}
          nextStepCallback={handleNextStep}
          pathToContact={pathToContact}
          setPathToContact={setPathToContact}
          setValue={setSelectedSubtype}
          backToLandingCallback={backToLandingCallback}
        />
      )}
      {step === Steps.Animation && (
        <Animation
          selectedIndustry={selectedIndustry}
          nextStepCallback={handleNextStep}
        />
      )}
      {step === Steps.Questionnaire && (
        <Questionnaire
          selectedIndustry={selectedIndustry}
          backToLandingCallback={backToLandingCallback}
        />
      )}
    </>
  );
};

export default HomePage;
