import { useState } from "react";
import "./ConnectButton.css";

const ConnectButton = ({ onClick }: { onClick: any }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    onClick();
  };

  return (
    <div
      key="nestoooo"
      className={`connect-btn ${isClicked ? "clicked disabled" : ""} no-select`}
      onClick={handleClick}
    >
      CONNECT
    </div>
  );
};

export default ConnectButton;
