import { CircularProgress } from "@mui/material";
import { useState } from "react";
import LogoLayer from "../../../layers/logo/LogoLayer";
import "./Forms.css";

export type Data = {
  farmType: string;
  location: string;
  communication: string;
};

type Errors = {
  farmType?: string;
  location?: string;
  communication?: string;
};

type Props = {
  backToLandingCallback?: () => void;
};

const AgricultureForm = ({ backToLandingCallback }: Props) => {
  const [info, setInfo] = useState<Data>({
    farmType: "",
    location: "",
    communication: "",
  });
  const [errors, setErrors] = useState<Errors>({});
  const [showSuccess, setShowSuncces] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSend = (): void => {
    if (isLoading) return;
    setIsLoading(true);
    setIsLoading(false);
    if (!validate()) return;

    setShowSuncces(true);
  };

  const validate = (): boolean => {
    const validationErrors: Errors = {};

    if (!info.farmType) {
      validationErrors.farmType = "Please enter farm type.";
    }

    if (!info.location) {
      validationErrors.location = "Please enter your location.";
    }

    if (!info.communication) {
      validationErrors.communication =
        "Please enter your preferred method of communication.";
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const propName = event.target.name;
    const propValue = event.target.value;

    setInfo((prev) => ({
      ...prev,
      [propName]: propValue,
    }));

    setErrors((prev) => ({
      ...prev,
      [propName]: undefined,
    }));
  };

  return (
    <>
      <LogoLayer backToLandingCallback={backToLandingCallback} />
      {showSuccess && (
        <div className="end-message">
          <p>
            Thank you for submitting your request. Someone will get in touch
            with you soon!
          </p>
          <button style={{}} onClick={backToLandingCallback}>
            HOME PAGE
          </button>
        </div>
      )}
      {!showSuccess && (
        <div className="questionnaire-main-cont">
          <div className="top-left-icon">
            <img
              src={"/backArrow.svg"}
              alt="freshearth"
              onClick={backToLandingCallback}
            />
          </div>
          <p className="questionnaire-title">
            Welcome! Please let us know three things about you.
          </p>
          <div className="questionnaire-input-box">
            <label>What type of farm are you inquiring about?</label>
            <input
              name="farmType"
              value={info.farmType}
              onChange={handleInputChange}
            />
            <span className="questionnaire-err-text" hidden={!errors.farmType}>
              {errors.farmType}
            </span>
          </div>

          <div className="questionnaire-input-box">
            <label>Where are you located? </label>
            <input
              name="location"
              value={info.location}
              onChange={handleInputChange}
            />
            <span className="questionnaire-err-text" hidden={!errors.location}>
              {errors.location}
            </span>
          </div>

          <div className="questionnaire-input-box">
            <label>How would you like us to communicate with you?</label>
            <input
              name="communication"
              value={info.communication}
              onChange={handleInputChange}
              placeholder="Phone, email, text, visit?"
            />
            <span
              className="questionnaire-err-text"
              hidden={!errors.communication}
            >
              {errors.communication}
            </span>
          </div>

          <div className="questionnaire-btn no-select" onClick={handleSend}>
            {isLoading ? (
              <CircularProgress sx={{ mr: 1, color: "white" }} size={25} />
            ) : (
              "Send"
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AgricultureForm;
