import AgricultureForm from "./forms/AgricultureForm";
import HealthForm from "./forms/HealthForm";
import InvestorForm from "./forms/InvestorForm";

type StepProps = {
  selectedIndustry?: string;
  backToLandingCallback?: () => void;
};

const Questionnaire = ({
  selectedIndustry,
  backToLandingCallback,
}: StepProps) => {
  switch (selectedIndustry) {
    case "Agriculture":
      return <AgricultureForm backToLandingCallback={backToLandingCallback} />;
    case "Health":
      return <HealthForm backToLandingCallback={backToLandingCallback} />;
    case "Investor":
      return <InvestorForm backToLandingCallback={backToLandingCallback} />;
    default:
      return <></>;
  }
};

export default Questionnaire;
