import { Snackbar, SnackbarOrigin } from "@mui/material";

type Props = {
  open: boolean;
  autoHideDuration: number;
  anchorOrigin: SnackbarOrigin | undefined;
  message: string;
  variant?: "success" | "error";
  onClose: () => void;
};

const NotificationSnackbar = ({
  open,
  message,
  anchorOrigin,
  autoHideDuration,
  variant,
  onClose,
}: Props) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      message={message}
      onClose={onClose}
      ContentProps={{
        sx: {
          color: "white",
          bgcolor: variant === "error" ? "red" : "green",
        },
      }}
    />
  );
};

export default NotificationSnackbar;
